<template>
  <base-page-fullscreen
    class="setup"
    dark-mode
    button-label="Cancel"
    @click-button="cancel"
  >
    <transition-fade>
      <div
        v-if="step === 1"
        key="1"
      >
        <form
          class="form"
          method="POST"
          @submit.prevent="submitForm"
        >
          <base-title :level="1">
            When do you want to send your weekly question to your teams?
          </base-title>

          <base-paragraph>
            Choose a day on which we send a question every week:
          </base-paragraph>

          <fieldset class="form__fieldset">
            <form-radio-button-group>
              <form-radio-button
                v-for="day in days"
                :key="day.value"
                v-model="form.schedule_day"
                :value="day.value"
                size="small"
                dark-mode
                :loading="$apollo.queries.user.loading"
              >
                {{ day.name }}
              </form-radio-button>
            </form-radio-button-group>
          </fieldset>

          <base-paragraph>
            Choose a time period in which we send the questions:
          </base-paragraph>

          <fieldset class="form__fieldset">
            <form-radio-button-group>
              <form-radio-button
                v-for="period in schedulePeriods"
                :key="period.id"
                v-model="form.schedule_period_id"
                :value="period.id"
                size="large"
                dark-mode
                :loading="$apollo.queries.user.loading"
              >
                <div>
                  {{ period.name }}
                </div>
                <div class="setup__time">
                  ({{ period.from }} - {{ period.to }})
                </div>
              </form-radio-button>
            </form-radio-button-group>
          </fieldset>

          <base-group
            class="form__actions"
          >
            <base-button
              type="secondary"
              label="Save schedule"
              size="large"
              :loading="!!isSubmitting"
              @click="submitForm"
            />
          </base-group>
        </form>
      </div>

      <div
        v-else-if="step === 2"
        key="2"
      >
        <img
          class="setup__graphic"
          src="~@/assets/images/graphic/party.svg"
        >

        <base-title :level="1">
          Let’s get this party started
        </base-title>

        <base-paragraph>
          Click on the button below and your first question will
          be sent out to your team next {{ selectedDay }} {{ selectedPeriod }}!
        </base-paragraph>

        <base-button
          type="secondary"
          label="Start Volved"
          size="large"
          :loading="!!isSubmitting"
          @click="finish"
        />
      </div>
    </transition-fade>
  </base-page-fullscreen>
</template>

<script>
/* Import Queries */
import USER_QUERY from '@/graphql/queries/User.gql';
import SCHEDULE_PERIODS_QUERY from '@/graphql/queries/SchedulePeriods.gql';

/* Import Mutations */
import UPDATE_USER_MUTATION from '@/graphql/mutations/UpdateUser.gql';

/* Import Classes and Helpers */
import Form from '@/utils/Form';
import { notifyFormError } from '@/utils/notifications';

export default {
  data() {
    return {
      id: this.$root.$data.userId,
      step: 1,
      form: new Form({
        schedule_day: null,
        schedule_period_id: '',
      }),
      days: [
        { name: 'Mo', day: 'Monday', value: 1 },
        { name: 'Tu', day: 'Tuesday', value: 2 },
        { name: 'We', day: 'Wednesday', value: 3 },
        { name: 'Th', day: 'Thursday', value: 4 },
        { name: 'Fr', day: 'Friday', value: 5 },
        { name: 'Sa', day: 'Saturday', value: 6 },
        { name: 'Su', day: 'Sunday', value: 7 },
      ],
      isSubmitting: 0,
    };
  },
  computed: {
    selectedDay() {
      return this.days[this.form.schedule_day - 1].day.toLowerCase();
    },
    selectedPeriod() {
      return this.user ? this.user.schedule_period.name.toLowerCase() : '';
    },
  },
  apollo: {
    user: {
      query: USER_QUERY,
      update(data) {
        if (Object.keys(data).length > 0 && data.constructor === Object) {
          this.form.set({
            schedule_day: data.user.schedule_day,
            schedule_period_id: data.user.schedule_period.id,
          });
        }
        return data.user;
      },
    },
    schedulePeriods: {
      query: SCHEDULE_PERIODS_QUERY,
    },
  },
  methods: {
    async submitForm() {
      this.isSubmitting += 1;
      // GraphQL Mutation
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: { id: this.id, ...this.form.data },
        })
        .then(() => {
          // Result
          this.step += 1;
        })
        .catch((error) => {
          // Error
          notifyFormError();
          this.form.errors.record(error);
        })
        .finally(() => {
          this.isSubmitting -= 1;
        });
    },
    async finish() {
      this.isSubmitting += 1;
      // GraphQL Mutation
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: { id: this.id, setup_completed: true },
        })
        .finally(() => {
          this.isSubmitting -= 1;
          // Open Dashboard
          this.$router.push({ name: 'dashboard' });
        });
    },
    cancel() {
      this.$router.push({
        name: 'dashboard',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.setup {
  &__time {
    font-weight: normal;
  }

  &__graphic {
    width: 100%;
    max-width: 560px;
    margin-bottom: 32px;
  }
}
</style>
